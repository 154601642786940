<template>
<div class="container">
    <div class="logo">
        <img :src="logoUrl" alt="梨子助教" />
    </div>
    <div class="system-list" v-if="!auth('ADMIN')">
        <div class="system-list-item" :class="{'active': systemIndex === 1}" @click="handleChangeSystemIndex(1)" v-if="auth('EDUCATION', 'MANAGER') && auth('school.education.manage', 'MANAGER')">
            <i class="iconfont icon-educational-management" /><span>教学管理</span>
        </div>
        <div class="system-list-item" :class="{'active': systemIndex === 2}" @click="handleChangeSystemIndex(2)" v-if="auth('OPERATION') && auth('school.operation.manage')">
            <i class="iconfont icon-operations-management" /><span>发育商测评</span>
        </div>
        <div class="system-list-item" :class="{'active': systemIndex === 3}" @click="handleChangeSystemIndex(3)" v-if="auth('PROVIDER')">
            <i class="iconfont icon-online-education" /><span>在线教育</span>
        </div>
        <div class="system-list-item" :class="{'active': systemIndex === 4}" @click="handleChangeSystemIndex(4)" v-if="systemVisible">
            <i class="iconfont icon-system" /><span>系统管理</span>
        </div>
    </div>
    <div class="login-info">
        <div class="user-type" @click="handleIdentityChange">
            <p class="main" v-if="auth('ADMIN')">管理员</p>
            <p class="main" v-else>{{userTypeCh}}{{belongName ? (': ' + belongName) : '' }}</p>
        </div>
        <el-dropdown size="medium" trigger="click">
            <div class="user">
                <span class="user-name">{{ user.name }}</span>
                <img v-if="user && user.avatar" :src="user.avatar" />
                <img v-else src="@/assets/images/default-avatar.png" />
            </div>
            <el-dropdown-menu slot="dropdown" class="dropdown-menu">
                <el-dropdown-item>
                    <el-button size="medium" type="text" style="width:100%"
                        @click="handlePasswordFormShow">修改密码
                    </el-button>
                </el-dropdown-item>

                <el-dropdown-item>
                    <el-button size="medium" type="text" style="width:100%"
                        @click="handleLogout">退 出
                    </el-button>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
    <template class="dialog-container">
        <el-dialog title="修改密码" :visible.sync="passwordVisible" class="changePasswordForm"
            :modal="false" @closed="handlePasswordFormClose"  width="500px">
            <div class="event-dialog-content">
                <el-form :model="passwordForm" label-width="80px" ref="passwordForm"
                    :rules="passwordRules" >
                    <el-form-item label="旧密码" prop="oldPass">
                        <el-input v-model="passwordForm.oldPass"
                            type="password" :show-password='true'
                            autocomplete="off" placeholder="请填写旧密码"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="新密码" prop="newPass" style="margin-bottom: 0;">
                        <el-input v-model="passwordForm.newPass"
                            type="password" :show-password='true'
                            autocomplete="off" placeholder="请填写新密码"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleEditPassword">保存</el-button>
                <el-button @click="handlePasswordFormClose">取消</el-button>
            </div>
        </el-dialog>
    </template>
</div>
</template>

<script>
import Core from '@/core';
import Const from '@/core/const';

import LOGO_URL from '@/assets/images/Logo.png'

const USER_TYPE = Const.USER_TYPE;

export default {
    data() {
        return {
            USER_TYPE,
            systemIndex: 1,

            user: Core.Data.getUser(),
            userType: Core.Data.getUserType(),
            belongName: Core.Data.getBelong().name,
            orgCurrSchool: Core.Data.getOrgCurrSchool() || {},

            passwordVisible: false,
            passwordForm: {
                oldPass: '',
                newPass: '',
            },
            passwordRules: {
                oldPass: [
                    { required: true, message: '请输入旧密码', trigger: 'blur' },
                ],
                newPass: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                ],
            },

            systemVisible: false,

            logoUrl: '',
        };
    },
    computed: {
        userTypeCh() {
            return Core.Const.USER_TYPE_MAP[this.userType]
        },
    },
    created() {
        let logoUrl =  Core.Data.getLogoUrl()
        this.logoUrl = logoUrl ? logoUrl : LOGO_URL
    },
    mounted() {
        this.initSystemIndex();
        this.checkLoginStatus();
        setTimeout(() => {
            if (this.userType === USER_TYPE.ORG_SCHOOL) {
                this.belongName = Core.Data.getOrgCurrSchool().name
            } else {
                this.belongName = Core.Data.getBelong().name
            }
        }, 300)
    },
    watch: {
        $route: {
            deep: true,
            handler(n) {
                this.checkLoginStatus();
                this.initSystemIndex();
            }
        },
    },
    methods: {
        auth: Core.Utils.auth,
        async checkLoginStatus() {
            const token = Core.Data.getToken();
            if (token) {
                this.getOrgConfig();
            } else {
                this.$route.replace('/login')
                return this.$message.warning('请先登录')
            }
        },
        // 切换侧边栏
        setSystemIndex(index) {
            this.systemIndex = index
            Core.Data.setSystemIndex(index)
        },
        initSystemIndex() {
            let systemIndex = Core.Data.getSystemIndex();
            if (systemIndex) {
                this.setSystemIndex(systemIndex)
                return
            }
            let classify = this.$route.meta ? this.$route.meta.classify || 1 : 1
            this.setSystemIndex(classify)
        },
        handleChangeSystemIndex(index) {
            this.setSystemIndex(index)
            switch (this.systemIndex) {
                case 1:
                    this.$router.push('/resources/resources-list');
                    break;
                case 2:
                    if(this.auth('SCHOOL')) {
                        this.$router.push('/operate-quotient/invite-list');
                    } else {
                        this.$router.push('/operate-set/channel-list');
                    }
                    break;
                case 3:
                    this.$router.push('/online-course/course-list');
                    break;
                case 4:
                    this.$router.push('/system-set/logo-set');
                    break;
            }
        },

        // 登出
        handleLogout() {
            this.$confirm('确定要退出登录吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Data.clearAllData();
                this.$router.replace({path: '/login'});
            });
        },

        // 切换身份
        handleIdentityChange() {
            this.$router.push({
                path: '/login',
                query: {
                    user_id: this.user.id
                }
            });
        },

        // 修改密码
        handlePasswordFormShow() { // 显示修改密码弹框
            this.passwordVisible = true;
        },
        handlePasswordFormClose() { // 取消修改密码
            this.passwordVisible = false;
            this.passwordForm = {
                oldPass: '',
                newPass: '',
            }
        },
        handleEditPassword() { // 确认修改密码
            Core.Api.User.passwordUpdate(
                this.passwordForm.oldPass,
                this.passwordForm.newPass
            ).then((res) => {
                this.$message.success('修改成功!')
                this.passwordVisible = false;
            });
        },

        // 获取 个性化配置
        getOrgConfig(){
            switch (this.userType) {
                case USER_TYPE.ORG:
                    this.systemVisible = true
                    Core.Api.Org.getOrgConfigDetail().then(res => {
                        let logo = res.detail.logo
                        Core.Data.setLogoUrl(logo)
                        this.logoUrl = logo ? logo : LOGO_URL
                    })
                    break;
                case USER_TYPE.ORG_SCHOOL:
                    let config = Core.Data.getBelong().config || {}
                    let type = Core.Data.getBelong().type

                    Core.Data.setLogoUrl(config.logo)
                    this.logoUrl = config.logo ? config.logo : LOGO_URL
                    this.systemVisible = type === 2 ? false : true
                    break;
                case USER_TYPE.SCHOOL:
                    let org_id = Core.Data.getBelong().org_id
                    Core.Api.Org.detail(org_id).then(res => {
                        let detail = res.detail
                        let config = detail.config || {}
                        Core.Data.setLogoUrl(config.logo)
                        this.logoUrl = config.logo ? config.logo : LOGO_URL
                        this.systemVisible = detail.type === 2 ? false : true
                    })
                    break;
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.container {
    width: 100vw;
    height: 60px;
    box-sizing: border-box;
    @include flex(row, space-between, center);
    position: relative;
    .logo {
        @include ftb;
        @include no-select;
        cursor: pointer;
        padding-left: 20px;
        img {
            max-width: 220px;
            height: 33px;
            margin-right: 10px;
        }
        .name {
            font-size: 12px;
            color: rgba(110, 118, 125, 1);
        }
    }
    .system-list {
        position: absolute;
        left: 240px;
        @include ftb;
        .system-list-item {
            @include ftb;
            cursor: pointer;
            font-size: 14px;
            color: #1D517E;
            opacity: .6;
            &.active, &:hover {
                opacity: 1;
            }
            i.iconfont {
                font-size: 14px;
                margin-right: 10px;
            }
            + .system-list-item {
                margin-left: 44px;
            }
        }
    }
    .login-info {
        @include ftb;
        .user-type {
            @include flex(column, center, center);
            margin-right: 16px;
            cursor: pointer;
            .main {
                background-color: #1D517E;
                color: #fff;
                margin-right: 8px;
                padding: 4px 5px;
                border-radius: 3px;
                font-size: 12px;
                line-height: 12px;
            }
            .sub {
                margin-top: 3px;
                font-size: 10px;
                color: #838383;
            }
        }
        .user {
            @include ftb;
            @include no-select;
            cursor: pointer;
            height: 50px;
            margin-right: 20px;
            padding-left: 20px;
            border-left: 1px solid #eef2f5;
            font-size: 14px;
            font-weight: 500;
            color: #62696f;
            img {
                width: 30px;
                height: 30px;
                margin-left: 10px;
                border-radius: 50%;
            }
        }
    }
    .changePasswordForm, .changePhoneForm, .changeSchool {
        background: rgba(0,0,0,0.4);
    }
    .changeSchool {
        .dialog-content {
            .form-item {
                display: flex;
                align-items: center;
                width: 100%;
                box-sizing: border-box;
                padding: 0 10px;
                + .form-item {
                    margin-top: 16px;
                }
                .key {
                    width: 100px;
                }
                .value {
                    width: calc(100% - 100px);
                }
            }
        }
    }
}
.dropdown-menu {
    li {
        text-align: center;
    }
    .belong-name {
        max-width: 200px;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        &::after {
            content: '';
            display: block;
            width: calc(100% - 20px);
            height: 1px;
            background-color: #eaedf0;
            position: absolute;
            bottom: 0px;
            left: 10px;
            z-index: 1;
        }
        &:hover {
            background-color: #fff;
        }
    }
}
</style>
